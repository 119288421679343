    @font-face{
        font-family:"MetaSerifWeb";
        src:url("Fonts/1473626/08e7f0f3-9b9a-4cd1-ab6c-88e3be788de3.eot?#iefix");
        src:url("Fonts/1473626/08e7f0f3-9b9a-4cd1-ab6c-88e3be788de3.eot?#iefix") format("eot"),url("Fonts/1473626/26da136f-be38-48ae-aec0-12ee1be2ec9d.woff2") format("woff2"),url("Fonts/1473626/aff01592-97e7-4a43-9594-d5f0df243a0c.woff") format("woff"),url("Fonts/1473626/bc8f94a1-f9f4-4b1e-bd75-a53e8b7826e2.ttf") format("truetype");
        font-weight: bolder;
        font-style: normal;
    }
    @font-face{
        font-family:"MetaSerifWeb";
        src:url("Fonts/1473631/ac7f67fb-3123-47f2-81f1-f6593f712df7.eot?#iefix");
        src:url("Fonts/1473631/ac7f67fb-3123-47f2-81f1-f6593f712df7.eot?#iefix") format("eot"),url("Fonts/1473631/0674cc49-4c48-4b08-83a5-75bcb9f78b26.woff2") format("woff2"),url("Fonts/1473631/c2986cb1-1936-4b8a-944a-19352c13b4e2.woff") format("woff"),url("Fonts/1473631/7582edf2-bdf9-496c-bf94-4abe59d7adbc.ttf") format("truetype");
        font-weight: bold;
        font-style: normal;
    }
    @font-face{
        font-family:"MetaSerifWeb";
        src:url("Fonts/1473636/34d5dd7b-24c5-4289-b0cc-c4614b5bda5a.eot?#iefix");
        src:url("Fonts/1473636/34d5dd7b-24c5-4289-b0cc-c4614b5bda5a.eot?#iefix") format("eot"),url("Fonts/1473636/0ff7de26-6e80-40ae-a1e4-0ec6b3ca708c.woff2") format("woff2"),url("Fonts/1473636/11cbf8c4-2fb9-4efb-8d9d-1099124cd38d.woff") format("woff"),url("Fonts/1473636/bd6a564e-9129-45b3-8e27-bb3a1fb5f7ff.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }
    @font-face{
        font-family:"MetaSerifWeb";
        src:url("Fonts/1473638/c0627f20-fbbe-438b-bf98-86b8a5a02d0a.eot?#iefix");
        src:url("Fonts/1473638/c0627f20-fbbe-438b-bf98-86b8a5a02d0a.eot?#iefix") format("eot"),url("Fonts/1473638/6c373ee9-ff34-4cd6-a8f7-afd99ff7bebd.woff2") format("woff2"),url("Fonts/1473638/6a5c76ed-57f0-4db9-bdcf-e545354f6d6d.woff") format("woff"),url("Fonts/1473638/23d09612-9116-41c6-b38c-041149e83fef.ttf") format("truetype");
        font-weight: normal;
        font-style: italic;
    }
