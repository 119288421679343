$enable-responsive-font-sizes: true;
$enable-caret: false;

// variables and overrides
//

$gray-100: change-color(#f8f9fa, $hue: 30, $saturation: 2%);
$gray-200: change-color(#e9ecef, $hue: 30, $saturation: 2%);
$gray-300: change-color(#dee2e6, $hue: 30, $saturation: 2%);
$gray-400: change-color(#ced4da, $hue: 30, $saturation: 2%);
$gray-500: change-color(#adb5bd, $hue: 30, $saturation: 2%);
$gray-600: change-color(#6c757d, $hue: 30, $saturation: 2%);
$gray-700: change-color(#495057, $hue: 30, $saturation: 2%);
$gray-800: change-color(#343a40, $hue: 30, $saturation: 2%);
$gray-900: change-color(#212529, $hue: 30, $saturation: 2%);

$dark-cerulean: #094074;
$queen-blue: #3C6997;
$gainsboro: #E0E0DF;
$dark-tangerine: #FAA916;
$dark-purple: #2D1E2F;
$maastricht-blue: #022B3A;
$rusty-red: #DB2B39;
$liver-chestnut: #8F7756;
$pullman-brown: #593f1c;

// non-scheme colors
$ku-crimson: #E3170A;

$body-color: rgba(0, 0, 0, 0.9);
$primary: $rusty-red;
$secondary: $gray-400;
$danger: red;

$font-family-base: 'MetaSerifWeb', serif;

:root {
  --primary-transparent: #{transparentize($primary, 0.9)}
}

@import "../node_modules/bootstrap/scss/bootstrap";
@import "./ff-meta-serif";
@import "./floating_labels";

body {
	font-variant-numeric: lining-nums;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > main {
    flex: 1 0 auto;
  }

  > footer {
    flex-shrink: 0;
  }
}

#home-search {
	margin: 15vw auto 1.5em;
}
.company-title {
	font-weight: bolder;
}
p.company-summary {
	columns: 20rem;
  column-gap: 30px;
	font-variant-numeric: oldstyle-nums;
}
dl {
	&:last-child {
		margin-bottom: 0;
	}
}
table {
	th {
		font-weight: normal;
	}
	thead {
		th {
			font-weight: bold;
		}
	}
	tfoot {
		th {
			font-weight: bold;
		}
	}
	tbody {
		tr.em {
			font-weight: bold;
			th {
				font-weight: bold;
			}
		}
		tr.strong {
			font-weight: bold;
			th {
				font-weight: bold;
			}
		}
	}
}
table.financials {
	th {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 20rem;
		vertical-align: middle;
	}

	thead th {
		border-bottom: 2px solid $nav-tabs-border-color;
	}

	tbody {
		td {
			vertical-align: middle;
			font-variant-numeric: tabular-nums lining-nums;
		}
		&:not(.generated-financials) {
			tr.strong {
				background-color: $gray-200;
			}
		}
		border-bottom: 2px solid $nav-tabs-border-color;
	}
}
.financials-tab {
	display: none;
}
#financials-format-tabs[data-selected='reported'] {
	#reported-financials-tab {
		display: block;
	}
}
#financials-format-tabs[data-selected='standardized'] {
	#standardized-financials-tab {
		display: block;
	}
}

.nav-tabs-scrollable {
  border-bottom: 1px solid $nav-tabs-border-color;

  .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: none;
    margin-bottom: -1px;

    .nav-item {
      margin-bottom: 0;
    }
  }
}

table.financials {
  & tbody[data-display-format="accounting"] .percent-value {
    display: none;
  }

  & tbody[data-display-format="percent"] .accounting-value {
    display: none;
  }
}

.toolbar-scrollable {
  flex-wrap: nowrap;
  overflow-x: auto;

  .btn {
    white-space: nowrap;
  }
}

dl > :last-child {
  margin-bottom: 0
}

.company-finder-backdrop {
  background-color: var(--gray);

  &.show {
    opacity: 0;
  }
}

.company-finder-modal .modal-content {
  border-radius: 0.8rem;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.41);

  @extend .shadow;

  .list-group-item:not(.active) {
    background-color: transparent;
  }
}

.sign-in-with-apple-btn {
  display: block;
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  line-height: 50px;
  font-family: var(--font-family-sans-serif);
  text-align: center;
  position: relative;
  font-size: 1.25rem;
  border-radius: 0.3rem;
  padding: 0;
  border: 0;

  .icon {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.sign-in-with-google-btn {
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media only screen and (min-width: 768px) {
  table.financials {
    position: relative;

    tbody th,
    thead th:first-child,
    tfoot th:first-child {
      background: linear-gradient(90deg, white 90%, transparentize(white, 1) 100%);
      text-shadow: 0 0 4px white;
      position: sticky;
      left: 0;
    }

    tbody tr.strong th {
      background: linear-gradient(90deg, $gray-200 90%, transparentize($gray-200, 1) 100%);
      text-shadow: 0 0 4px $gray-200;
    }
  }
}

.dropdown-menu {
  @extend .shadow-sm;

  .custom-control-label {
    cursor: pointer;
  }
}

.btn-secondary {
  @include button-variant(
    transparentize($primary, .95),
    transparent,
  )

  &,
  &:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active, .show >
  &.dropdown-toggle,
  &:focus, &.focus {
    color: $primary;
  }

  &:hover {
    background-color: transparentize($primary, .9);
  }

  &:focus, &.focus {
    box-shadow: transparentize($primary, .8) 0px 0px 0px 0.2rem;
  }
}

.list-group-item.active {
  color: var(--primary);
  background-color: transparentize($primary, .9);
  border-color: rgb(222, 189, 190);
}

button:focus {
  outline: none;
}

.btn-flex, .btn-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 38px;

  &.btn-sm {
    height: 32px;
  }

  &.btn-lg {
    height: 48px;
  }
}

.btn-icon {
  padding: 0;
  width: 38px;

  &.btn-sm {
    width: 32px;
  }

  &.btn-lg {
    width: 48px;
  }
}

.badge-secondary {
  color: var(--primary);
  background-color: transparentize($primary, .9);
}

a.badge-secondary {
  &:hover, &:focus {
    color: var(--primary);
    background-color: transparentize($primary, .85);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem transparentize($primary, .95);
  }
}

.table th.with-link {
  padding: 0;

  > a {
    padding: .75rem;
    display: block;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: var(--light);
    }
  }
}

th.ticker {
  width: 7em;
}

th.marketcap, th.totalrevenue {
  width: 10em;
}

th.name {
  min-width: 13em;
  width: 50%;
}

.dropdown-item.active .rbt-highlight-text {
  color: white
}

.rbt-highlight-text {
  padding-right: 0;
  padding-left: 0;
  background-color: transparentize($primary, .9);
}
